import React, { ReactElement } from "react";
import { Header } from "./layout/Header";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import "./sass/style.scss";
import { router } from "./constants/router";
import { Messages } from "./components/Messages/Messages";
import { Footer } from "./layout/Footer";

const App: React.FC = (): ReactElement => {
  return (
    <Router>
      <Header />
      <Switch>
        {router.map((i) => {
          return (
            <Route
              path={i.path}
              exact={i.exact}
              component={i.component}
            ></Route>
          );
        })}
      </Switch>
      <Footer />
      <Messages />
    </Router>
  );
};

export { App };
