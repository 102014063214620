export interface socialItem {
  link: string;
  name: string;
}
export const social: socialItem[] = [
  {
    link: 'https://t.me/zhebil',
    name: 'telegram',
  },
  {
    link: 'https://github.com/zhebil',
    name: 'github',
  },
  {
    link: 'tel:+380980129984',
    name: 'phone',
  },
  {
    link: 'https://www.instagram.com/zhebil',
    name: 'instagram',
  },
];
