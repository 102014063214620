import { FC } from "react";
import { constants } from ".";
import { MafiaGame } from "../games/Mafia.game";
import { PokerGame } from "../games/Poker.game";
import { SelectPlayersPage } from "../pages/SelectPlayers.page";

export interface IRouterItem {
  path: string;
  exact: boolean;
  component: FC;
}
const { ROUTES } = constants;
const router: IRouterItem[] = [
  { path: ROUTES.MAFIA, exact: false, component: MafiaGame },
  { path: ROUTES.POKER, exact: false, component: PokerGame },
  { path: ROUTES.SELECT_PLAYER, exact: false, component: SelectPlayersPage },
];
export { router };
